import {
  LIFESIZE_RESPONSE_SUCCESS,
  localhostDNS,
} from "../constants/constants";
import Logger from "js-logger";

const portRangeSize = 32;
const portRangeStart = 50000;

function httpGetAsync(
  uri: string,
  onSuccess: (variable: string) => void,
  onFailure: (variable: string) => void
) {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => {
    if (xmlHttp.readyState === 4) {
      if (xmlHttp.status === 200) {
        onSuccess(xmlHttp.responseText);
      } else {
        onFailure(xmlHttp.responseText);
      }
    }
  };
  xmlHttp.open("GET", uri, true);
  xmlHttp.send(null);
}

/**
 * If extension provided, go directly to that route
 * If no extension, and start is true, go to /start route in desktop
 */
const getLoopbackServerUri = (port: number, extension?: string, start: boolean = false) => {
  let uri = `${localhostDNS}:${port}`;
  const cacheBuster = `?cachebuster=${Math.random()}`;
  if (!!extension) {
    uri += `${cacheBuster}&join=${extension}`;
  } else if (start) {
    uri += `${cacheBuster}&start=true`;
  }
  return uri;
};

export async function checkIfAppIsCurrentlyRunning(
  extension: string,
  successCallback: Function,
  failureCallback?: Function,
  isOutlook?: boolean,
) {
  let desktopFound = false;
  let failureCount = 0;
  for (let i = 0; i < portRangeSize; i++) {
    const loopbackServerUri = getLoopbackServerUri(
      portRangeStart + i,
      extension,
      isOutlook
    );
    if (desktopFound) {
      break;
    }
    try {
      httpGetAsync(
        loopbackServerUri,
        // eslint-disable-next-line no-loop-func
        (responseText: string) => {
          if (responseText === LIFESIZE_RESPONSE_SUCCESS && !desktopFound) {
            desktopFound = true;
            Logger.debug(`App found!`);
            successCallback();
          }
        },
        // eslint-disable-next-line no-loop-func
        () => {
          failureCount++;
          if (failureCount >= portRangeSize && !desktopFound) {
            if (failureCallback) {
              failureCallback();
            }
          }
          Logger.debug(`App not found on port: ${portRangeStart + i}`);
        }
      );
    } catch (error) {
      Logger.debug(`App not found on port: ${portRangeStart + i}`);
    }
  }
}

export function checkIfAppHasFinishedInstallation(extension: string, onSuccessfulLaunch: Function) {
  checkIfAppIsCurrentlyRunning(extension, onSuccessfulLaunch, () => {
    setTimeout(() => { checkIfAppHasFinishedInstallation(extension, onSuccessfulLaunch); }, 2000);
  });
}
