import { createMuiTheme, Theme } from '@material-ui/core';
import { colors, MUIComponentsTheme } from '@lifesize/clients.mui-components';
import AzoRegular from '@lifesize/clients.mui-components/lib/fonts/RuiAbreu-AzoSans-Regular.woff2';
import _merge from 'lodash/merge';

const azo = {
  fontFamily: 'azo-sans-web',
  fontStyle: 'normal',
  fontWeight: 400,
  fontDisplay: 'swap',
  src: `
    local('azo-sans-web'),
    url(${AzoRegular}) format('woff2')
  `
};

const fontFamilyPrimary = 'azo-sans-web';

MUIComponentsTheme.typography.fontFamily = 'azo-sans-web';
MUIComponentsTheme.palette.background.default = colors.white;
MUIComponentsTheme.overrides = _merge(MUIComponentsTheme.overrides, {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [azo],
      '.MuiContainer-root': {
        display: 'flex',
        padding: 0
      }
    }
  },
  MuiButton: {
    root: {
      fontFamily: fontFamilyPrimary
    }
  },
  MuiTextField: {
    root: {
      '& input': {
        fontFamily: fontFamilyPrimary
      }
    },
  },
  MuiTypography: {
    root: {
      fontFamily: fontFamilyPrimary
    },
    body1: {
      fontFamily: fontFamilyPrimary,
      fontSize: '1.4rem'
    },
    button: {
      fontFamily: fontFamilyPrimary
    },
    h1: {
      fontFamily: fontFamilyPrimary,
      fontSize: '2rem'
    },
    h2: {
      fontFamily: fontFamilyPrimary
    },
    h3: {
      fontFamily: fontFamilyPrimary,
      fontSize: '1.5rem',
      fontWeight: 600
    },
    h4: {
      fontFamily: fontFamilyPrimary
    },
    subtitle1: {
      fontFamily: fontFamilyPrimary
    },
    subtitle2: {
      fontFamily: fontFamilyPrimary
    }
  }
});

export const theme: Theme = createMuiTheme(MUIComponentsTheme);
