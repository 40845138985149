import React from 'react';
import intl from 'react-intl-universal';
import { makeStyles, Box } from '@material-ui/core';
import { isChrome, isFireFox, isSafari } from '../utils/browserDetectionUtils';



const getLabel = () => {
  if (isChrome() || isFireFox() || isSafari()) {
    return intl.getHTML('helperTextChrome')
  }
  return intl.getHTML('helperText'); // is IE
}

const Helper = () => {
  const classes = useStyles();

  const getContainerClass = () => {
    if (isChrome()) {
      return `${classes.container} ${classes.chrome}`;
    } else if (isFireFox() || isSafari()) {
      return `${classes.container} ${classes.firefox}`;
    }
    return classes.container;
  }

  const getArrowClass = () => {
    if (isFireFox() || isSafari()) {
      return `iconcurve ${classes.arrow} ${classes.arrowFirefox}`;
    }
    return `iconcurve ${classes.arrow} `;
  }

  return (
    <Box data-testid="helperContainer" className={getContainerClass()}>
      <i className={getArrowClass()} />
      <Box data-testid="helperTitle" className={classes.helper}>
        {getLabel()}
      </Box>
    </Box>
  )
};

const useStyles = makeStyles(() => ({
  helper: {
    display: 'flex',
    backgroundColor: 'rgba(48, 16, 146, 0.1)',
    border: 'solid 1px rgb(46, 16, 146)',
    fontSize: '24px',
    padding: '15px',
    borderRadius: '4px',
    textAlign: 'center',
  },
  container: {
    position: 'absolute',
    bottom: '120px',
    left: '55%',
    display: 'flex',
    animation: 'fadein 1s',
  },
  arrow: {
    color: 'rgb(46, 16, 146)',
    fontSize: '100px',
    marginRight: '15px',
    position: 'relative',
    top: '40px',
    transform: 'scaleX(-1) rotate(180deg)',
  },
  chrome: {
    left: '5%',
    bottom: '90px'
  },
  firefox: {
    top: '80px',
    right: '16px',
    bottom: 'auto',
    left: 'auto',
    width: '184px',
    minHeight: '173px'
  },
  arrowFirefox: {
    top: '-30px',
    transform: 'scaleX(1) rotate(0deg)'
  }
}));

export default Helper;
