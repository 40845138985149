import React from 'react';
import intl from 'react-intl-universal';
import { ReactComponent as Buddy } from '@lifesize/ux-assets/vectors/svgLogos/lsBuddyTM.svg';
import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '@lifesize/clients.mui-components';

export const SuccessComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.successContainer}>
      <Buddy className={classes.logo}/>
      <div data-testid="closeWindowTitle" className={classes.title}>{intl.getHTML('successTitle')}</div>
    </div>
  )
};

const useStyles = makeStyles((theme: Theme) => ({
  successContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: colors.stormcloud,
    flex: '1 1 auto',
    justifyContent: 'center'
  },
  logo: {
    height: '73px',
    color: colors.lifesize,
  },
  title: {
    fontSize: '3.0rem',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
}));
