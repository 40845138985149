import React, { useEffect, useState } from 'react';
import Logger from 'js-logger';
import { localizationUtils } from '@lifesize/clients.localization';
import { Theme, makeStyles } from '@material-ui/core';
import App from './App';
import Footer from './Footer';
import Header from './Header';
import { getCurrentLanguageId, loadLocales, setCurrentLanguageId} from 'utils/localeUtils';
import { getSourceIsOutlook, getExtensionParam } from 'utils/urlUtils';

const isFromOutlook: boolean = getSourceIsOutlook();

const isOutlookLoaded =
  isFromOutlook
  && window.Office
  && window.Office.context
  && window.Office.context.mailbox
  && window.Office.context.mailbox.item
  && window.Office.context.mailbox.item.location;

const getExtensionFromOutlook = async () => {
  let extension = '';
  await window.Office.context.mailbox.item.location.getAsync(
    {},
    (result: any) => {
      if (!!result.value) {
        const index = result.value.lastIndexOf("/");
        const extensionQueryParam = index ? result.value.substr(index + 1, result.value.length) : result.value;
        if (Number.isInteger(extensionQueryParam)) {
          Logger.debug(`Found extension on invite: ${extensionQueryParam}`);
          extension = extensionQueryParam;
        }
        else {
          // Logging simply because outlook can be tough to troubleshoot
          Logger.debug(`A non-valid extension was passed to launcher: ${extensionQueryParam}`);
        }
      }
    })
  return extension;
};

const getOfficeLocale = async () => {
  await Office.onReady();
  return Office.context.displayLanguage;
};

const MainLayout = () => {
  const classes = useStyles();
  const [locale, setLocale] = useState(getCurrentLanguageId());
  const [initDone, setInitDone] = useState(false);
  const [extension, setExtension] = useState(getExtensionParam());

  const handleSetLocale = (newLocale: string) => {
    setInitDone(false);
    setLocale(newLocale);
  }


  useEffect(() => {
    const initApp = async (selectedLocale: string) => {
      let currentLocale = selectedLocale;
      if (!initDone) { // first run only
        if (isFromOutlook && window.Office) { // office will never have a lang set in the qs
          currentLocale = localizationUtils.getLanguageId(await getOfficeLocale());
          setCurrentLanguageId(currentLocale);
          setLocale(getCurrentLanguageId());
        }
      }

      if (isOutlookLoaded) {
        const outlookExt = await getExtensionFromOutlook();
        setExtension(outlookExt);
      }

      await loadLocales(locale);

      setInitDone(true);
    };
    initApp(locale);
  }, [initDone, locale]);

  if (initDone) {
    return (
      <div className={classes.mainContainer}>
        <Header setLocale={handleSetLocale} langCode={locale} />
        <App extension={extension} />
        <Footer langCode={locale} />
      </div>
    );
  }
  return (<div />);
}

const useStyles = makeStyles((theme: Theme) => ({
mainContainer: {
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  flexDirection: 'column'
}
}));

export default MainLayout;
