import React from 'react';
import { Box, makeStyles} from '@material-ui/core';

interface IProps {
  children: React.ReactElement;
}

const ImageContainer = ({ children }: IProps) => {
  const classes = useStyles();
  return (
    <Box mb={5} className={classes.container}>
      {children}
    </Box >
  );
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '185px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  }
}));

export default ImageContainer;