import React from 'react';
import { Box, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';
import DownloadButton from 'components/DownloadButton';
import { ReactComponent as WinDownloadImage } from '@lifesize/ux-assets/vectors/downloadPopupWin.svg';
import ImageContainer from 'components/FireFox/ImageContainer';

const WindowsDownload = () => {
  return (
    <Box data-testid="winDownloadInstructions">
      <ImageContainer>
        <WinDownloadImage />
      </ImageContainer>
      <Box mt={1}>
        <Typography variant="body1">
          {intl.getHTML('firefoxWinDownload1')}
        </Typography>
        <Typography variant="body1">
          {intl.get('firefoxWinDownload2')}
        </Typography>
      </Box>
      <DownloadButton />
    </Box>
  );
}

export default WindowsDownload;
