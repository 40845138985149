import React from 'react';
import intl from 'react-intl-universal';
import { localizationUtils } from '@lifesize/clients.localization';
import { colors } from '@lifesize/clients.mui-components';
import { makeStyles } from '@material-ui/core';

interface IProps {
  langCode: string
}

const Footer = ({ langCode }: IProps) => {
  const classes = useStyles();

  return (
    <div data-testid="footer" className={classes.footer}>
      {
        intl.getHTML(
          'footer',
          {
            date: new Date().getFullYear(),
          }
        )
      }
      &nbsp;
      <a href={localizationUtils.getPrivacyPolicy(langCode)}>{intl.get('privacyPolicy')}</a>
      &nbsp;
      |
      &nbsp;
      <a href={localizationUtils.getTermsOfService(langCode)}>{intl.get('terms')}</a>
    </div>
  )
};

const useStyles = makeStyles(() => ({
  footer: {
    "backgroundColor": colors.charcoal,
    flex: '0 0 30px',
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center",
    "fontSize": "12px",
    "color": "#a0a0a0"
  }
}));

export default Footer;
