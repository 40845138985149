import React from 'react';
import { Box, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';
import DownloadButton from 'components/DownloadButton';
import { ReactComponent as MacDownloadImage } from '@lifesize/ux-assets/vectors/downloadPopupMac.svg';
import ImageContainer from 'components/FireFox/ImageContainer';

const MacDownload = () => {
  return (
    <Box data-testid="macDownloadInstructions">
      <ImageContainer>
        <MacDownloadImage height={185}/>
      </ImageContainer>
      <Typography variant="body1">
        {intl.getHTML('firefoxMacDownload1')}
      </Typography>
      <Box mt={2}>
        <Typography variant="body1">
          {intl.get('firefoxMacDownload2')}
        </Typography>
      </Box>
      <DownloadButton />
    </Box>
  );
}

export default MacDownload;
