import React from 'react';
import { Box, Typography, makeStyles, Theme, Link } from '@material-ui/core';
import intl from 'react-intl-universal';
import { colors } from '@lifesize/clients.mui-components';
import { isMacOs } from 'utils/browserDetectionUtils';
import WindowsDownload from 'components/FireFox/WindowsDownload';
import MacDownload from 'components/FireFox/MacDownload';
import MacInstall from 'components/FireFox/MacInstall';
import WindowsInstall from 'components/FireFox/WindowsInstall';
import MacOpen from 'components/FireFox/MacOpen';
import WindowsOpen from 'components/FireFox/WindowsOpen';
import ExtensionBox from 'components/FireFox/ExtensionBox';
import { launchProtocol } from 'constants/constants';


interface IProps {
  extension: string;
}

const FirefoxFailed = ({ extension }: IProps) => {
  const isMac = isMacOs();
  const classes = useStyles();
  return (
    <Box mt={5}>
      <Box data-testid="firefoxInstructionsHeader" className={classes.title}>
        <Typography variant="h1" className={classes.titleText}>
          {intl.get('firefoxHeader1')}
        </Typography>
        <Typography variant="h1" className={classes.titleText}>
          {intl.get('firefoxHeader2')}
        </Typography>
        <Typography variant="h1" className={classes.titleText}>
          {intl.get('firefoxHeader3')}
        </Typography>
      </Box>
      <Box className={classes.instructionContainer}>
        <Box mx={4} my={2} className={classes.instructionPanel}>
          <Box mb={5}>
            <Typography data-testid="downloadHeader" variant="h3" align="center">
              1. {intl.get('download')}
            </Typography>
          </Box>
          {isMac ?
            <MacDownload /> :
            <WindowsDownload />
          }
        </Box>
        <Box mx={4} my={2} className={classes.instructionPanel}>
          <Box mb={5}>
            <Typography data-testid="installHeader" variant="h3" align="center">
              2. {intl.get('install')}
            </Typography>
          </Box>
          {isMac ?
            <MacInstall /> :
            <WindowsInstall />
          }
        </Box>
        <Box mx={4} my={2} className={classes.instructionPanel}>
          <Box mb={5}>
            <Typography data-testid="joinHeader" variant="h3" align="center">
              3. {isMac ? intl.get('firefoxJoinMac') : intl.get('firefoxJoinWin') }
            </Typography>
          </Box>
          {extension && <ExtensionBox extension={extension} />}
          {isMac ?
            <MacOpen /> :
            <WindowsOpen />
          }
        </Box>
      </Box>
      <Box my={6} className={classes.finalOptions}>
        <Box mx={4}>
          <Link data-testid="alreadyHaveAppLink" href={`${launchProtocol}${extension}`}>
            <Typography variant="subtitle1" className={classes.link}>
              {intl.get('alreadyHaveApp')}
            </Typography>
          </Link>
        </Box>
        {extension &&
          <Box mx={4}>
            <Link data-testid="otherwaysLink" href={`https://call.lifesizecloud.com/otherways/${extension}`}>
              <Typography variant="subtitle1" className={classes.link}>
                {intl.get('otherWays')}
              </Typography>
            </Link>
          </Box>
        }
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: 'center'
  },
  titleText: {
    margin: theme.spacing(1)
  },
  instructionContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    flexFlow: "row wrap",
    marginTop: theme.spacing(5),
  },
  instructionPanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: 1,
    minWidth: '300px',
    maxWidth: '400px'
  },
  link: {
    color: colors.lifesize,
    cursor: 'pointer'
  },
  finalOptions: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default FirefoxFailed;
