import React from 'react';
import { ReactComponent as LsHorizontalR } from '@lifesize/ux-assets/vectors/svgLogos/lsHorizontalR.svg';
import { ReactComponent as HelpOutline } from '@lifesize/ux-assets/vectors/svgIcons/help-outline.svg';
import { localizationUtils } from '@lifesize/clients.localization';
import { colors, LanguageSwitcher } from '@lifesize/clients.mui-components';
import { makeStyles, Box } from '@material-ui/core';
import { getLanguageOptions } from 'utils/localeUtils';

interface IProps {
  langCode: string;
  setLocale: (code: any) => void;
}

const Header = ({ langCode, setLocale }: IProps) => {
  const classes = useStyles();
  
  return (
    <Box px={2} className={classes.container}>
      <Box className={classes.logo} data-testid="lifesizeLogo">
        <LsHorizontalR />
      </Box>
      <Box className={classes.right}>
        <Box mr={3}>
        <a className={classes.link} href={localizationUtils.getHelpPage(langCode)} rel="noopener noreferrer" target="_blank">
          <HelpOutline className={classes.help} />
        </a>
        </Box>
        <LanguageSwitcher
          className={classes.languageSwitcher}
          languageId={langCode}
          languageOptions={getLanguageOptions()}
          handleSelect={(lang: any) => setLocale(lang.id)}
          data-testid="languageButton"
        />
      </Box>
    </Box>
  )
};

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: colors.lifesize,
    flex: '0 0 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.white,
    fontSize: '1.4rem'
  },
  logo: {
    color: colors.white,
    display: 'flex',
    flex: '0 0 auto',
    '& svg': {
      height: 25,
      width: 120
    }
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  link: {
    height: '24px',
    width: '24px',
  },
  help: {
    height: '24px',
    width: '24px',
    color: colors.white,
  },
  languageSwitcher: {
    color: colors.white
  }
}));


export default Header;
