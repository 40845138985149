import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import Logger from 'js-logger';
import React from 'react';
import ReactDOM from 'react-dom';
import MainLayout from './components/MainLayout';
import './index.css';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './theme';

Logger.useDefaults();
Logger.setLevel(Logger.DEBUG);

const start = async () => {
  if (typeof Office !== 'undefined') {
    await Office.onReady();
    Logger.debug('Office JS API ready');
  }
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainLayout />
    </ThemeProvider>,
    document.getElementById('root') as HTMLElement
  );
};

start();


