import React from 'react';
import intl from 'react-intl-universal';
import { ReactComponent as Buddy } from '@lifesize/ux-assets/vectors/svgLogos/lsBuddyTM.svg';
import {
  downloadURL,
} from "../constants/constants";
import { isChrome, isEdge, isIE } from "../utils/browserDetectionUtils";
import Helper from "./Helper";
import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '@lifesize/clients.mui-components';

interface IProps {
  extension: string;
  otherWaysLink: string;
  isDownloading: boolean;
}

const getSubTitle = () => {
  if (isIE() || isEdge()) {
    return intl.getHTML("failedSubTitleEdgeIE", {
      download: downloadURL,
      id: "lsDownloadLink"
    });
  }
  if (isChrome()) {
    return intl.getHTML("failedSubTitleChrome", {
      download: downloadURL,
      id: "lsDownloadLink"
    });
  }
  return intl.getHTML("failedSubTitleSafariFF", {
    download: downloadURL,
    id: "lsDownloadLink"
  });
};

export default function FailedComponent({ extension, otherWaysLink, isDownloading }: IProps) {
  const classes = useStyles();
  return (
    <div className={classes.app}>
      <Buddy className={classes.logo}/>
      <div data-testid="failedTitle" className={classes.title}>{intl.getHTML("failedTitle")}</div>
      <div data-testid="failedSubTitle" className={classes.subTitle}>
        {getSubTitle()}
        <br />
        <br />
        <br />
        <div data-testid="failedOtherwaysTitle">
          {intl.getHTML("failedOtherWays", {
            otherWays: `${otherWaysLink}`
          })}
        </div>
      </div>
      {isDownloading && <Helper />}

    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  app: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "96px",
    color: colors.stormcloud,
    fontSize: "1.4rem",
    height: "100%"
  },
  logo: {
    height: '73px',
    color: colors.lifesize,
  },
  title: {
    fontSize: '3.0rem',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  subTitle: {
    textAlign: 'center',
    maxWidth: '593px'
  }
}));
