import React from 'react';
import { Box, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';
import { ReactComponent as MacInstallImage } from '@lifesize/ux-assets/vectors/installMac.svg';
import ImageContainer from 'components/FireFox/ImageContainer';

const MacInstall = () => {
  return (
    <Box data-testid="macInstallInstructions">
      <ImageContainer>
        <MacInstallImage height={185}/>
      </ImageContainer>
      <Box mb={2}>
      <Typography variant="body1">
        {intl.getHTML('firefoxMacInstall1')}
      </Typography>
      </Box>
      <Typography variant="body1">
        {intl.get('firefoxMacInstall2')}
      </Typography>
    </Box>
  );
}

export default MacInstall;

