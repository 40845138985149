import React from 'react';
import { Box, Typography, makeStyles, Theme, Link } from '@material-ui/core';
import intl from 'react-intl-universal';
import { colors } from '@lifesize/clients.mui-components';

const DownloadButton = () => {
  const classes = useStyles();
  return (
    <Box mt={3} className={classes.container}>
      <Link data-testid="downloadLink" className={classes.buttonLink} href="https://download.lifesizecloud.com/download" target="_blank">
        <Typography variant="button">
          {intl.get('download')}
        </Typography>
      </Link>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonLink: {
    background: colors.lifesize,
    color: colors.white,
    padding: '16px 32px 13px',
    boxShadow: 'none',
    borderRadius: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
    '&:hover, &:active, &:visited': {
      color: colors.white,
      textDecoration: 'none',
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default DownloadButton;