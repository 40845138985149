import React from 'react';
import { Box, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';
import { ReactComponent as WindowsInstallImage } from '@lifesize/ux-assets/vectors/installWin.svg';
import ImageContainer from 'components/FireFox/ImageContainer';

const WindowsInstall = () => {
  return (
    <Box data-testid="winInstallInstructions">
      <ImageContainer>
        <WindowsInstallImage />
      </ImageContainer>
      <Box mb={2}>
      <Typography variant="body1">
          {intl.getHTML('firefoxWinInstall1')}
      </Typography>
      </Box>
      <Typography variant="body1">
        {intl.get('firefoxWinInstall2')}
      </Typography>
    </Box>
  );
}

export default WindowsInstall;

