import { Localization, localizationConstants } from '@lifesize/clients.localization';
import intl from 'react-intl-universal';
import { getLangParam } from '../utils/urlUtils';

export const DEFAULT_LOCALE = localizationConstants.defaultLanguageId;
const initLanguageId = getLangParam() || navigator.language || DEFAULT_LOCALE;
const localization = new Localization({ id: initLanguageId, excludedIds: ['ru-RU'] });

// locale data
const locales = {
  'cs-CZ': require('../locales/cs-CZ.json'),
  'de-DE': require('../locales/de-DE.json'),
  'en-US': require('../locales/en-US.json'),
  'es-ES': require('../locales/es-ES.json'),
  'fi-FI': require('../locales/fi-FI.json'),
  'fr-FR': require('../locales/fr-FR.json'),
  'it-IT': require('../locales/it-IT.json'),
  'ja-JP': require('../locales/ja-JP.json'),
  'ko-KR': require('../locales/ko-KR.json'),
  'nl-NL': require('../locales/nl-NL.json'),
  'no-NO': require('../locales/no-NO.json'),
  'pl-PL': require('../locales/pl-PL.json'),
  'pt-BR': require('../locales/pt-BR.json'),
  'ru-RU': require('../locales/ru-RU.json'),
  'sv-SE': require('../locales/sv-SE.json'),
  'zh-Hans': require('../locales/zh-Hans.json'),
  'zh-Hant': require('../locales/zh-Hant.json'),
};

export const getLanguageOptions = () => {
  return localization.languageOptions;
};

export const loadLocales = async (locale: string) => {
  const appLocale = locale || DEFAULT_LOCALE;
  await intl.init({
    currentLocale: appLocale,
    locales,
    fallbackLocale: DEFAULT_LOCALE
  });
};

export const getCurrentLanguageId = (): string => {
  return localization.languageId || DEFAULT_LOCALE;
};

export const setCurrentLanguageId = (newId: string) => {
  localization.languageId = newId;
};
