import React from 'react';
import { Box, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';

const WindowsOpen = () => {
  return (
    <Box data-testid="winOpenInstructions">
      <Box mb={2}>
        <Typography variant="body1">
          {intl.get('firefoxWinLaunch1')}
        </Typography>
      </Box>
    </Box>
  );
}

export default WindowsOpen;

