/**
 * Open a new browser window, and close the task pane
 */
const openUrl = (uri?: string) => {
  // Every browser treats window.open differently
  // This is workaround for more consistent behavior
  if (!!uri) {
    Object.assign(document.createElement('a'), { target: '_blank', href: `${uri}` }).click();
  }
};

const closeTaskPane = () => {
  if (Office && Office.context && Office.context.ui && Office.context.ui.closeContainer) {
    Office.context.ui.closeContainer();
  }
};

/**
 * Open a new browser window, and close the task pane
 * Delay this action so user can see wait animation, and UX isn't as jarring.
 * User will see wait animation for delay time duration
 */
const openUrlAndCloseTaskPane = (uri: string) => {
  setTimeout(() => {
    openUrl(uri);
    closeTaskPane();
  }, 1000);
};

export default { closeTaskPane, openUrlAndCloseTaskPane };
