import Logger from 'js-logger';
import React, { useEffect, useState, useCallback } from 'react';
import { callMeURL, webAppURI, START_ROUTE, launcherAppURI } from '../constants/constants';
import { isWebAppSupported, isGuestFlowSupported, isIpad } from '../utils/browserDetectionUtils';
import { checkIfAppIsCurrentlyRunning } from '../utils/detectorUtils';
import Outlook from '../utils/outlookUtils';
import { getAppCheckParam, getReferringApps, goToGuestFlow, forceDownloadOnFail } from '../utils/urlUtils';
import FailedComponent from './Failed';
import { LoadingComponent } from './Loading';
import { SuccessComponent } from './Success';

interface IProps {
  extension: string;
}

export default function App({ extension }: IProps) {
  const shouldNotDetect = getAppCheckParam() === 'false';
  const { isFromOutlook, isFromMicrosoft, isFromTeams, isFromOutlookV2 } = getReferringApps();
  const autoDownloadApp = !isFromMicrosoft && !isFromOutlook && !isFromTeams && !isFromOutlookV2;
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSuccessfulLaunch = useCallback(() => {
    setFailed(false);
    setSuccess(true);
    if (isFromOutlook) {
      Outlook.closeTaskPane();
    }
  }, [isFromOutlook]);

  const onFailedLaunchSupportedBrowser = useCallback(() => {
    // Redirect back to call me page if there is an extension
    // or redirect to webapp (start a call) if there is no extension
    const uri = !!extension ? `${callMeURL}${extension}?appCheck=true` : `${webAppURI}${START_ROUTE}`;
    if (isFromOutlook) {
      Outlook.openUrlAndCloseTaskPane(uri);
    }
    else {
      // Just redirect inside the same browser window
      window.location.replace(uri);
    }
  }, [extension, isFromOutlook]);

  const onFailedLaunchUnsupportedBrowser = useCallback(() => {
    if (isFromOutlook) {
      if (shouldNotDetect) {
        Outlook.closeTaskPane();
      }
      else {
        Outlook.openUrlAndCloseTaskPane(`${launcherAppURI}?appCheck=false`);
      }
      return;
    }
    setFailed(true);
    setSuccess(false);
  }, [isFromOutlook, shouldNotDetect]);

  const onFailedLaunch = useCallback(() => {
    if (!forceDownloadOnFail && isWebAppSupported()) {
      onFailedLaunchSupportedBrowser();
    }
    else if (!forceDownloadOnFail && (isGuestFlowSupported() || isIpad())) {
      goToGuestFlow();
    }
    else {
      onFailedLaunchUnsupportedBrowser();
    }
  }, [onFailedLaunchSupportedBrowser, onFailedLaunchUnsupportedBrowser]);

  useEffect(() => {
    if (shouldNotDetect) {
      onFailedLaunch();
      return;
    }
    try {
      if (!success) {
        checkIfAppIsCurrentlyRunning(extension, onSuccessfulLaunch, onFailedLaunch, isFromOutlook);
      }
    } catch (e) {
      Logger.error(`Error trying to check for desktop app ${e}`);
      onFailedLaunch();
    }
  }, [extension, isFromOutlook, onFailedLaunch, onSuccessfulLaunch, shouldNotDetect, success]);

  if (failed) {
    return (
      <FailedComponent
        autoDownloadApp={autoDownloadApp}
        extension={extension}
        onSuccessfulLaunch={onSuccessfulLaunch}
      />
    )
  }
  else if (success) {
    return (<SuccessComponent />);
  }

  return (
    <LoadingComponent />
  );
}
