import Bowser from 'bowser';
import _get from 'lodash/get';

/** Cached instance of bowser util for re-use */
const getBrowser = () => {
  const userAgent = _get(navigator, 'userAgent');
  return Bowser.getParser(userAgent);
};

const browser = getBrowser();

const getOsInfo = () => {
  const osVersion = getBrowser().getOS();
  const versionParts = osVersion.version ? osVersion.version.split('.') : [];
  return {
    name: osVersion.name ? osVersion.name.toLowerCase() : '',
    versionName: osVersion.versionName || '',
    major: versionParts && versionParts.length ? versionParts[0] : 0,
    minor: versionParts && versionParts.length > 1 ? versionParts[1] : 0
  };
};

const getOS = (): string => {
  return getOsInfo().name;
};

/** Returns lower-case string - ex: 'desktop', 'tablet', 'mobile', etc. */
const getType = (): string => {
  return (getBrowser().getPlatform().type || '').toLowerCase();
};

export const isMacOs = () => {
  return getOS() === 'macos'
};

export const isWindows = () => {
  return getOS() === 'windows'
};

export function isIE() {
  return browser.satisfies({ ie: '>10' });
};

export function isEdge() {
  return browser.satisfies({ edge: '>16' });
}

export function isChrome() {
  return browser.satisfies({ chrome: '>45' });
}

export function isFireFox() {
  return browser.satisfies({ firefox: '>40' });
}

export function isSafari() {
  return browser.satisfies({ safari: '>=10' });
}

export const isIpad = () => {
  return getOS() === 'macos' && getType() === 'desktop' && navigator.maxTouchPoints > 1;
};

export function isWebAppSupported() {
  return browser.satisfies({
    windows: {
      chrome: '>=73',
      chromium: '>73',
      edge: '>=76'
    },
    macos: {
      chrome: '>=73',
      chromium: '>73',
      edge: '>=76'
    },
    linux: {
      chrome: '>=73',
      chromium: '>73',
      edge: '>=76'
    },
    'Chrome OS':{
      chrome: '>=73'
    }
  });
}

export function isGuestFlowSupported() {
  return browser.satisfies({
    chrome: '>=73',
    chromium: '>=73',
    edge: '>=76',
    // safari: '>=12'
  });
}

// msLaunchUri method (Edge/IE 10+ on Windows 8+)
export const hasMSLaunchURI = !!navigator.msLaunchUri as boolean;
