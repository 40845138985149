import React from 'react';
import { Box, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';

const MacOpen = () => {
  return (
    <Box data-testid="macOpenInstructions">
      <Box mb={2}>
        <Typography variant="body1">
          {intl.getHTML('firefoxMacLaunch1')}
        </Typography>
      </Box>
      <Typography variant="body1">
        {intl.get('firefoxMacLaunch2')}
      </Typography>
    </Box>
  );
}

export default MacOpen;

