enum OfficeSources {
  MICROSOFT = 'microsoft',
  TEAMS = 'teams',
  OUTLOOK = 'outlook',
  OUTLOOKV2 = 'outlookv2'
}

const getUrlParameter = (name: string) => {
  // eslint-disable-next-line
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results: RegExpExecArray | null = regex.exec(window.location.search);
  const returnValue = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  // Because desktop apps can mangle the URL,
  // We do a split on '?' and take the part we know is ours
  return returnValue.split('?')[0];
};

const forceDownloadOnFail = (getUrlParameter('forceDownloadOnFail') || '').toLowerCase() === 'true';

const getSourceParam = (): string => {
  return (getUrlParameter('source') || '').toLowerCase();
};

const getAppCheckParam = (): string => {
  return (getUrlParameter('appCheck') || '').toLowerCase();
};

const getLangParam = (): string => {
  return (getUrlParameter('lang') || '').toLowerCase();
};

const getExtensionParam = (): string => {
  return (getUrlParameter('ext') || '').toLowerCase();
};

const getReferringApps = () => {
  const referringApp = getSourceParam();
  let isFromMicrosoft = false;
  let isFromOutlook = false;
  let isFromTeams = false;
  let isFromOutlookV2 = false;
  switch (referringApp) {
    case OfficeSources.MICROSOFT:
      isFromMicrosoft = true;
      break;
    case OfficeSources.OUTLOOK:
      isFromOutlook = true;
      break;
    case OfficeSources.TEAMS:
      isFromTeams = true;
      break;
    case OfficeSources.OUTLOOKV2:
      isFromOutlookV2 = true;
      break;
  }
  return {
    isFromTeams,
    isFromOutlook,
    isFromMicrosoft,
    isFromOutlookV2
  }
};

const getSourceIsOutlook = (): boolean => getSourceParam() === OfficeSources.OUTLOOK;

const goToGuestFlow = () => {
  const currentUrl = new URL(window.location.href);
  const guestUrl = new URL(process.env.REACT_APP_GUEST_APP_URI as string);
  guestUrl.pathname = currentUrl.searchParams.get('ext') || '';
  window.location.replace(guestUrl.href);
}

export {
  getReferringApps,
  getUrlParameter,
  getAppCheckParam,
  getLangParam,
  getExtensionParam,
  getSourceIsOutlook,
  goToGuestFlow,
  forceDownloadOnFail
}
