import React, { useEffect, useState } from 'react';
import {
  callMeURL,
  downloadURL,
  SKIP_DOWNLOAD_FOR_TEST
} from "constants/constants";
import { isFireFox, isSafari } from "utils/browserDetectionUtils";
import {
  checkIfAppHasFinishedInstallation,
} from "utils/detectorUtils";
import FirefoxFailed from 'components/FireFox/FirefoxFailed';
import SafariFailed from 'components/Safari/SafariFailed';
import DefaultFailed from 'components/DefaultFailed';
import { makeStyles, Box } from '@material-ui/core';
import { colors } from '@lifesize/clients.mui-components';

interface IProps {
  autoDownloadApp: boolean;
  extension: string;
  onSuccessfulLaunch: Function;
}

export default function FailedComponent({ autoDownloadApp, extension, onSuccessfulLaunch }: IProps) {
  const classes = useStyles();
  const [isDownloading, setIsDownloading] = useState(autoDownloadApp);
  const otherWaysLink = extension
    ? `${callMeURL}otherways/${extension}`
    : `${callMeURL}downloads`;

  const iframeSource = localStorage.getItem(SKIP_DOWNLOAD_FOR_TEST)
    ? ""
    : downloadURL;

  const startDownload = () => {
    setIsDownloading(true);
  };

  useEffect(() => {
    // componentDidMount...
    const downloadLink = document.getElementById("lsDownloadLink");

    if (downloadLink) {
      downloadLink.addEventListener("click", startDownload);
    }
    
    checkIfAppHasFinishedInstallation(extension, onSuccessfulLaunch);

    // componentWillUnmount...
    return () => {
      if (downloadLink) {
        downloadLink.removeEventListener("click", startDownload);
      }
    };

  }, [extension, onSuccessfulLaunch]);

  const getComponentByBrowser = () => {
    if (isFireFox()) {
      return <FirefoxFailed extension={extension} />
    } else if (isSafari()) {
      return <SafariFailed extension={extension} />
    } else {
      return <DefaultFailed extension={extension} isDownloading={isDownloading} otherWaysLink={otherWaysLink} />
    }
  }

  return (
    <Box className={classes.failedContainer}>
      {getComponentByBrowser()}
      {autoDownloadApp && (
        <iframe
          src={iframeSource}
          style={{ display: "none" }}
          title="lsAutoDownload"
        />
      )}
    </Box>
  );
}
const useStyles = makeStyles(() => ({
  "failedContainer": {
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "color": colors.stormcloud,
    flex: '1 1 auto'
  },
}
));

