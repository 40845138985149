import React from 'react';
import intl from 'react-intl-universal';
import { ReactComponent as Buddy } from '@lifesize/ux-assets/vectors/svgLogos/lsBuddyTM.svg';
import { FadeLoader } from 'react-spinners';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { colors } from '@lifesize/clients.mui-components';

export const LoadingComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.app}>
      <Box mb={2}>
        <Buddy className={classes.logo}/>
      </Box>
      <div data-testid="checkingAppTitle" className={classes.title}>{intl.getHTML('initTitle')}</div>
      <FadeLoader color="#290088" height={15} width={5} />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '96px',
    color: colors.stormcloud,
    fontSize: '1.4rem',
    height: '100%',
    flex: '1 1 auto'
  },
  logo: {
    height: '73px',
    color: colors.lifesize,
  },
  title: {
    fontSize: '3.0rem',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  }
}));
