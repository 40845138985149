import React, { useState } from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import intl from 'react-intl-universal';
import { colors } from '@lifesize/clients.mui-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from '@lifesize/ux-assets/vectors/svgIcons/file-copy.svg';

interface IProps {
  extension: string;
}

const ExtensionBox = ({ extension }: IProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyClicked = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500);
  }
  const classes = useStyles();
  return (
    <Box data-testid="extensionContainer" mb={5} className={classes.container}>
      <Typography variant="subtitle1" className={classes.extensionText} align={'center'}>
        {intl.get('firefoxExtension')}
      </Typography>
      <Typography variant="subtitle1" className={classes.extensionValue} align={'center'}>
        <CopyToClipboard text={extension} onCopy={() => { handleCopyClicked() }}>
          <Box data-testid="extensionText" className={classes.extensionBox}>
            {extension}
            <CopyIcon className={`${classes.copyIcon} ${isCopied ? classes.copyIconSuccess : ''}`} />
          </Box>
        </CopyToClipboard>
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '185px'
  },
  extensionText: {
    color: colors.stormcloud
  },
  extensionValue: {
    color: colors.lifesize
  },
  extensionBox: {
    position: 'relative',
    cursor: 'pointer'
  },
  copyIcon: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    marginLeft: theme.spacing(1),
    color: colors.stormcloud
  },
  copyIconSuccess: {
    color: colors.brightGreen
  }
}));

export default ExtensionBox;
